<template>
  <div>
    <spinner-loader :loading="loading" />

    <suggestion-form
      v-if="!loading"
      :suggestion="suggestion"
      :disabled="true"
    >
      <template #additional-buttons>
        <div class="mb-1 text-right">
          <b-button
            v-if="suggestion.approvalStatus.status === 'Pending'"
            variant="primary"
            @click="showReasonModal()"
          >
            Reject
          </b-button>
          <b-button
            v-if="suggestion.approvalStatus.status === 'Pending'"
            class="mx-2"
            variant="primary"
            @click="saveToEntities"
          >
            Save To Entities
          </b-button>
          <b-button
            v-if="['Pending','Approved'].includes(suggestion.approvalStatus.status) && !suggestion.published"
            variant="primary"
            @click="saveAndPublish"
          >
            Save and Publish
          </b-button>
        </div>
      </template>
    </suggestion-form>

    <b-modal
      id="reason-modal"
      centered
      title="Reject Confirmation"
      hide-footer
      @hidden="rejectionReason=''"
    >
      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent>
          <text-input
            id="reason"
            name="Reason"
            :text.sync="rejectionReason"
          />
          <div class="text-right">
            <b-button
              class="mr-1"
              @click="$bvModal.hide('reason-modal')"
            >
              Cancel Reject
            </b-button>
            <b-button
              variant="primary"
              @click="handleSubmit(rejectOrganizer)"
            >
              Confirm Reject
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="set-user-as-organizer-modal"
      centered
      :title="`${ publish ? 'Publish' : 'Approve' } Confirmation`"
      hide-footer
    >
      <validation-observer #default="{handleSubmit}">
        <b-form @submit.prevent>
          <b-checkbox
            v-model="suggestion.set_user_as_organizer"
            v-b-tooltip.hover
            class="my-2"
            :title="!suggestion.business_email?'Provide email to set the user as admin':''"
            :disabled="!suggestion.business_email || (publish && suggestion.set_user_as_organizer)"
          >
            Set user as organization admin
          </b-checkbox>
          <div class="text-right">
            <b-button
              class="mr-1"
              @click="$bvModal.hide('set-user-as-organizer-modal')"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              @click="handleSubmit(approveOrganization)"
            >
              {{ publish ? 'Publish' : 'Approve' }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import SuggestionForm from '@/common/components/OrganizationSuggestions/SuggestionForm.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'

export default {
  name: 'OrgSuggestionEdit',
  components: { SuggestionForm, SpinnerLoader, TextInput },
  data() {
    return {
      suggestion: { address: {} },
      rejectionReason: '',
      publish: false,
      loading: false,
    }
  },
  created() {
    this.getSuggestion()
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return { successfulOperationAlert }
  },
  methods: {
    getSuggestion() {
      this.loading = true
      this.$entities.get(`internalops/entity/${this.$route.params.id}`).then(res => {
        this.suggestion = {
          ...res.data.data,
          media_files: res.data.data.media_files.map(image => image.url),
          address: {
            ...res.data.data.address[0],
            lat: res.data.data.location.coordinates[1],
            lng: res.data.data.location.coordinates[0],
          },
          logo: [res.data.data.logo],
        }
      }).finally(() => {
        this.loading = false
      })
    },
    rejectOrganizer() {
      this.$entities.post(`/internalops/organizer/${this.suggestion.id}/reject`, { reason: this.rejectionReason }).then(() => {
        this.successfulOperationAlert('Org is rejected successfully')
        this.$bvModal.hide('reason-modal')
        this.getSuggestion()
      })
    },
    saveToEntities() {
      this.showApproveModal()
      this.publish = false
    },
    saveAndPublish() {
      this.showApproveModal()
      this.publish = true
    },
    showApproveModal() {
      this.$bvModal.show('set-user-as-organizer-modal')
    },
    approveOrganization() {
      this.$entities.post(`/internalops/organizer/${this.suggestion.id}/accept`, this.suggestion, { params: { activate: this.publish } }).then(() => {
        this.successfulOperationAlert(`Org is ${this.publish ? 'published' : 'saved to entities'} successfully`)
        this.$bvModal.hide('set-user-as-organizer-modal')
        this.getSuggestion()
      })
    },
    showReasonModal() {
      this.$bvModal.show('reason-modal')
    },
  },
}
</script>

<style>

</style>
